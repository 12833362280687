.navbar-brand {
  display: flex;
  grid-area: logo;
  height: 3.75em;
  padding: 0.25em;
  justify-self: start;
}

.HeaderTitle {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  font-size: x-large;
  font-weight: 600;
}

nav {
  background-color: #6c69fc;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.Nav a {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  transition: 0.5s;
  text-decoration: none;
}
.dropdown-language a:hover,
.dropdown-menu a:hover {
  transform: none !important;
}
.dropdown-language {
  color: #000;
}

.nav-foot a {
  color: #fff;
}
.Nav a:hover {
  transform: scale(1.1);
}

#toggler {
  padding: 8px;
  border-radius: 8px;
  border: black solid 2px;
}

/* CSS for mobile  */
@media (max-width: 700px) {
  .Nav {
    background: rgba(108, 105, 252, 0.95);
  }
  .Nav a {
    color: #fff;
  }
}
