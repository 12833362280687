.theme__dark {
  background-color: #1f2023;
  transition: 0.5s;
  height: 100vh;
  width: 100vw;
}
.theme__dark * {
  color: white !important;
}

.theme__dark .Content * {
  background-color: #1f2023;
}

.theme__dark div[class*="tags"] span {
  background-color: rgb(153, 0, 255) !important;
}
.theme__dark #showcase article {
  background-color: rgb(212, 148, 255, 0.187);
}
/*.theme__dark #footer,
.theme__dark nav {
  background-color: rgb(212, 148, 255, 0.187);
}*/
/*.theme__dark #shadow * {
  color: $text !important;
}*/

body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

.App {
  height: 100vh;
  width: 100vw;
  background-color: #fff;
}

.App .Content,
.theme__dark .Content {
  padding-top: 3.1rem;
}

#sectionLanding {
  background-color: #222573;
}

#sectionLandingProject {
  background-color: #fafafa;
}
#sectionAbout {
  background: url("../assets//pattern.jpg") no-repeat;
  background-size: cover;
}

input[name="lastName"] ~ label,
input[name="firstName"] ~ label {
  margin-left: 0.75rem;
}
